<template>
  <div class="about pb-10">
    <h1 class="text-drawdown font-display md:text-mdlg text-mdbs tracking-wide leading-tight">
        About the project
    </h1>
    <p class="py-2">
        Top Climate Solutions for Kids is an adaptation of <a href="https://www.drawdown.org/" target="_blank" class="font-bold text-drawdown hover:underline">Project Drawdown</a> written for 5th-grade students. Our team of educators, activists, undergraduate and graduate students at the University of Colorado Boulder created this to be an accesible resource for anyone to use to learn or educate others about effective climate solutions.
    </p>
    <!-- <a class="py-2 font-md text-drawdown text-smd tracking-wide hover:underline hover:text-sky focus:text-sky underline lg:no-underline">
      Download the top 20 solutions as a PDF
    </a>
    <br>
    <a class="py-2 font-md text-drawdown text-smd tracking-wide hover:underline hover:text-sky focus:text-sky underline lg:no-underline" href="#team">
      Learn more about our team & contributors
    </a> -->
    <p class="pb-2 pt-2 italic">
        This resource was created as part of Enacting Climate by Inside the Greenhouse at the University of Colorado Boulder.
    </p>

    <div class="flex flex-row flex-wrap lg:w-1/2 py-2">
      <router-link to="/about/teacherguide" class="m-2 p-2 px-5 bg-sky75 text-center text-smd hover:text-white focus:text-white rounded-md hover:bg-drawdown active:bg-drawdown focus:bg-drawdown transition duration-500 ease-in-out motion-reduce:transition-none">
        Teacher Guide
      </router-link>
      <router-link to="/about/credits" class="m-2 p-2 px-5 bg-sky75 text-center text-smd hover:text-white focus:text-white rounded-md hover:bg-drawdown active:bg-drawdown focus:bg-drawdown transition duration-500 ease-in-out motion-reduce:transition-none">
        Our team & contributors
      </router-link>
    </div>

    <router-view/>
 
  </div>
</template>

<script>
import { mapState, mapMutations} from "vuex";

export default {
  name: 'About',
  components: {
    
  },
  computed: {
        ...mapState(["solutionIndex"])
  },
  methods: {
        ...mapMutations(["setSolutionIndex"])
  },
}

</script>